import React from "react";

import {
  Typography,
  makeStyles,
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
} from "@material-ui/core";
import Link from "../Link";
import { lighten } from "@material-ui/core/styles";
import { N1Icon } from "./icons/N1Icon";
import { N23Icon } from "./icons/N23Icon";
import { M1Icon } from "./icons/M1Icon.js";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2rem",
    minHeight: "50vh",
  },
  cards: {
    height: "350px",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    display: "grid",
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.8),
      "& $icon": {
        fill: theme.palette.primary.main,
      },
      "& $btn": {
        background: lighten(theme.palette.primary.main, 0.2),
      },
    },
  },
  card: {
    background: "transparent",
  },
  btn: {
    color: theme.palette.primary.light,
    background: theme.palette.primary.main,
    transition: "0.5s",
  },
  icon: {
    fontSize: "5rem",
    transition: "0.5s",
  },

  link: {
    textDecoration: "none",
    textAlign: "center",
  },
  title: {
    fontSize: "clamp(2rem, 6vw, 4rem)",
  },
  div: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  action: {
    justifyContent: "center",
    alignItems: "end",
  },
}));

const Dienstleistungen = () => {
  const classes = useStyles();
  const prices = useSelector((state) => state.prices.items);

  const year = new Date().getFullYear();

  const cardIcons = (type) => {
    switch (type) {
      case "n1":
        return <N1Icon className={classes.icon} />;
      case "n2":
      case "n3":
        return <N23Icon className={classes.icon} />;
      default:
        return <M1Icon className={classes.icon} />;
    }
  };
  return (
    <Container className={classes.root}>
      <Typography
        variant="h2"
        gutterBottom
        align="center"
        className={classes.title}
      >
        Unsere Preise {year}
      </Typography>
      <div className="flex justify-center items-center pb-4 sm:my-0">
        {/* <div className="flex items-center">
          <label className="mr-2 text-[#070606] text-[1.5rem] font-[700] leading-[1.5rem]">
            2022
          </label>
          <ToggleField
            handleChange={handleChange}
            id={1}
            value="next_year"
            isPicked={false}
          />
          <label className="ml-2 text-[#070606] text-[1.5rem] font-[700] leading-[1.5rem]">
            2023
          </label>
        </div> */}
        {/* <h1 className="font-[700] text-[1.5rem] leading-10 text-lightGreen">
          2022
        </h1>
        <CustomeToggle year={year} handleChange={handleChange} />
        <h1 className="font-[700] text-[1.5rem] leading-10 text-lightGreen">
          2023
        </h1> */}
      </div>
      <Grid container spacing={2}>
        {(prices || []).map((p) => {
          let revenueType = "";
          switch (p.type) {
            case "m1":
              revenueType = "";
              break;
            case "other":
              revenueType = " Baumpflanzaktion";
              break;
          }
          return (
            <Grid item xs={12} md={4} key={p.type}>
              <Link href="/register" className={classes.link}>
                <Card className={classes.cards} elevation={0}>
                  <Card
                    align="center"
                    elevation={0}
                    square
                    className={classes.card}
                  >
                    {/* "E-Auto PKW (M1)" */}
                    <CardHeader
                      title={
                        p.type == "n1"
                          ? "leichtes E-Nutzfahrzeug (N1)"
                          : p.type === "n2"
                          ? "schweres E-Nutzfahrzeug (N2)"
                          : p.type === "n3"
                          ? "E-Nutzfahrzeug ab 12 Tonnen (N3)"
                          : `E-Auto PKW (M1${revenueType})`
                      }
                      titleTypographyProps={{ variant: "h6" }}
                    />
                    <CardContent>
                      {cardIcons(p.type)}
                      <Typography
                        style={{ fontSize: "1.10rem", paddingTop: "2.25rem" }}
                        color="textPrimary"
                        align="center"
                        component="p"
                      >
                        <b>{p.price} €</b> pro Fahrzeug und Jahr <br />
                        {["m1", "n1"].includes(p.type) && (
                          <Typography
                            style={{ fontSize: "1.05rem" }}
                            color="textPrimary"
                            align="center"
                            component="p"
                          >
                            <b>{p.price + 15} €</b> für das erste Fahrzeug für
                            Neukunden in 2025 <br />
                          </Typography>
                        )}
                      </Typography>
                      {/* <Typography>
                            oder wählen Sie die “Pflanz einen Baum”- Aktion und
                            erhalten eine Auszahlung i.H.v <br />
                            <b> {p.p_price} €</b>
                          </Typography> */}
                    </CardContent>
                  </Card>
                  <CardActions className={classes.action}>
                    <Button
                      variant="contained"
                      size="large"
                      component={Link}
                      className={classes.btn}
                      href="/register"
                    >
                      Jetzt Registrieren
                    </Button>
                  </CardActions>
                </Card>
              </Link>
            </Grid>
          );
        })}
        {/* <Grid item xs={12} md={6}>
          <Link href="/register" className={classes.link}>
            <Card className={classes.cards} elevation={0}>
              <Card
                align="center"
                elevation={0}
                square
                className={classes.card}
              >
                <CardHeader
                  title="leichtes E-Nutzfahrzeug (N1)"
                  titleTypographyProps={{ variant: "h6" }}
                />
                <CardContent>
                  <N1Icon className={classes.icon} />
                  <Typography
                    variant="h6"
                    color="textPrimary"
                    align="center"
                    component="p"
                  >
                    <b>450 €</b> pro Fahrzeug und Jahr
                  </Typography>
                </CardContent>
              </Card>
              <CardActions className={classes.action}>
                <Button
                  variant="contained"
                  size="large"
                  component={Link}
                  className={classes.btn}
                  href="/register"
                >
                  Jetzt Registrieren
                </Button>
              </CardActions>
            </Card>
          </Link>
        </Grid> */}
      </Grid>
      <div id="ablauf"></div>
    </Container>
  );
};

export default Dienstleistungen;

// const cards = [
//   {
//     title: "E-Auto (M1)",
//     icon: <DirectionsBusIcon />,
//     text: "310 € pro Fahrzeug und Jahr",
//     href: "/register",
//   },
//   {
//     title: "Leichte E-Nutzfahrzeuge (N1)",
//     icon: <DirectionsBusIcon />,
//     text: "500 € pro Fahrzeug und Jahr",
//     href: "/register",
//   },
//   {
//     title: "E-Busse",
//     icon: <AirportShuttleIcon />,
//     text: "13000 € pro Fahrzeug und Jahr",
//     href: "/register",
//   },
//   {
//     title: "Flottenbetreiber",
//     icon: <DirectionsBusIcon />,
//     text: "Registrieren Sie 10 Fahrzeuge und erhalten Sie von uns ein Angebot. Sichern Sie sich <b>310€</b> pro Fahrzeug und Jahr.",
//     href: "/register",
//   },
// ];
