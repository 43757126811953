import React from "react";

const HeroBanner = () => {
  return (
    <div className="mt-20 flex items-center gap-x-6 bg-orange-400 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm leading-6 text-white">
        <strong>HINWEIS</strong>: Neukunden-Bonus: Erhalten Sie eine höhere
        Prämie auf das erste E-Fahrzeug der Klasse M1 ODER N1! &nbsp;
      </p>
      <div className="flex flex-1 justify-end" />
    </div>
  );
};

export default HeroBanner;
